import { useRouter } from 'next/router'
import React from 'react'
import { useForm } from 'react-hook-form'

import { CraftFilterExplanationListsFragment } from '../lib/craftCategoryExplanationListsFragment.generated'
import {
  TweakwiseNavigationFacet,
  TweakwiseNavigationSelectedAttribute,
} from '../lib/customTypes'
import updateUrlWithTweakwiseFacetFilter from '../lib/updateUrlWithTweakwiseFacetFilter'
import { LandingPageUrlFragment } from '../lib/useLandingPageUrls.generated'
import CategoryFilter from './CategoryFilter'

interface Props {
  facets: TweakwiseNavigationFacet[]
  landingPageUrls: LandingPageUrlFragment[]
  filterExplanations?: CraftFilterExplanationListsFragment
  hasShallowRouteUntilSubmit?: boolean
  setIsFacetMenuActive: (isFacetMenuActive: boolean) => void
}

const CategoryFiltersForm = ({
  facets,
  landingPageUrls,
  filterExplanations,
  hasShallowRouteUntilSubmit = false,
  setIsFacetMenuActive,
  ...other
}: Props) => {
  const router = useRouter()
  const { register, handleSubmit } =
    useForm<TweakwiseNavigationSelectedAttribute>() //TODO[BTPWA-645]: include default values

  const onSubmit = (facet: TweakwiseNavigationSelectedAttribute) => {
    setIsFacetMenuActive(true)
    updateUrlWithTweakwiseFacetFilter(router, facet, hasShallowRouteUntilSubmit)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} {...other}>
      {facets.map((facet, index) => {
        const filterExplanation =
          filterExplanations?.filterExplanationItems.find(
            (item) => item?.filterTitle === facet.facetSettings.attributeName,
          ) ?? undefined

        return (
          <CategoryFilter
            key={`${facet.facetSettings.attributeName}-${index}`}
            register={register}
            facet={facet}
            landingPageUrls={landingPageUrls}
            filterExplanation={filterExplanation}
            onChange={onSubmit}
          />
        )
      })}
    </form>
  )
}

export default CategoryFiltersForm
