import React from 'react'

function CrossRoundIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 22 22"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M11,21.8C5.1,21.8,0.2,16.9,0.2,11S5.1,0.2,11,0.2S21.8,5.1,21.8,11S16.9,21.8,11,21.8z M11,1.8
	c-5.1,0-9.2,4.1-9.2,9.2s4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2S16.1,1.8,11,1.8z"
      />
      <rect
        fill="currentColor"
        fillRule="nonzero"
        x="6"
        y="10.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.5565 11.0001)"
        width="9.9"
        height="1.5"
      />
      <rect
        fill="currentColor"
        fillRule="nonzero"
        x="10.3"
        y="6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -4.5565 11.0002)"
        width="1.5"
        height="9.9"
      />
    </svg>
  )
}

export default CrossRoundIcon
