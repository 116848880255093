import { NextRouter } from 'next/router'

/**
 * Function to update URL with Tweakwise sort key
 *
 * @param router from next/route useRouter()
 * @param sortKey Sort key the URL should be updated with
 */

export default function updateUrlWithTweakwiseSortKey(
  router: NextRouter,
  sortKey: string,
) {
  const encodedSortKey = encodeURIComponent(sortKey)

  router.query.sort = encodedSortKey ?? []

  router.push({
    pathname: router.pathname,
    query: {
      ...router.query,
    },
  })
}
