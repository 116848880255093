/**
 * Function to convert URL facet param into into an array of TweakwiseNavigationFacetFilter objects with facet key and values
 *
 * Example input: `trampoline_shape%3ARound%2Fcolor%3AGray`
 *
 * Example result:
 * ```
 * [
 *  {
 *    "key": "color",
 *    "values": [
 *      "Gray"
 *    ]
 *  },
 *  {
 *    "key": "trampoline_shape",
 *    "values": [
 *      "Round"
 *    ]
 *  }
 * ]
 * ```
 *
 * @param urlParam URL param that should be converted into array of TweakwiseNavigationFacetFilter objects
 * @returns Array of TweakwiseNavigationFacetFilter objects
 */

export default function getTweakwiseFacetFiltersFromUrlFacetParam(
  urlParam: string,
) {
  const urlParamFacets = decodeURIComponent(urlParam).split('/')

  const tweakwiseNavigationFacetFilters = urlParamFacets.map((facet) => {
    const [key, valuesString] = facet.split(/[:=]/)
    const values = valuesString
      ?.split('#')
      .map((value) => decodeURIComponent(value).replace(/\+/g, ' '))

    return {
      key,
      values,
    }
  })

  return tweakwiseNavigationFacetFilters
}
