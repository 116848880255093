import { TweakwiseNavigationFacet } from './customTypes'
import getTweakwiseFacetFiltersFromUrlFacetParam from './getTweakwiseFacetFiltersFromUrlFacetParam'

/**
 * Function to get Tweakwise navigation variables to pass as query params
 *
 * @param categoryUid Category's uid that used for query's uid
 * @param tweakwiseItemNumber Category's Tweakwise item number that should be used for query's tweakwiseItemNumbers
 * @param facets Available Tweakwise Navigation facets
 * @param facetParam Next route's facet param to extract active facets
 * @param currentPath Next route's current path (router.asPath)
 * @param initialLandingPageFacetParam Initial landing page facet param
 * @returns object with Tweakwise Navigation variables
 */

export default function getLandingPageUrlsVariables(
  categoryUid: string,
  tweakwiseItemNumber: string | null,
  facets: TweakwiseNavigationFacet[],
  facetParam: string,
  currentPath: string,
  initialLandingPageFacetParam?: string,
) {
  const selectedTweakwiseFacetFilters = facetParam
    ? getTweakwiseFacetFiltersFromUrlFacetParam(facetParam)
    : initialLandingPageFacetParam
    ? getTweakwiseFacetFiltersFromUrlFacetParam(initialLandingPageFacetParam)
    : []

  const tweakwiseFacetFilters = facets.map((facet) => {
    const key = facet.facetSettings.attributeName
    const values = facet.attributes.map((attribute) => attribute.title)

    return {
      key,
      values,
    }
  })

  return {
    categoryUid,
    tweakwiseItemNumbers: [Number(tweakwiseItemNumber)],
    facets: tweakwiseFacetFilters,
    selectedFacets: selectedTweakwiseFacetFilters,
    currentUri: currentPath,
  }
}
