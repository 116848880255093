import { ButtonUnstyled } from '@emico-react/buttons'
import styled from '@emotion/styled'
import { Plural, t, Trans } from '@lingui/macro'
import { NextRouter } from 'next/router'
import React from 'react'

import { minWidth } from '@emico/styles'

import { CraftFilterExplanationListsFragment } from '../lib/craftCategoryExplanationListsFragment.generated'
import { TweakwiseNavigationFacet } from '../lib/customTypes'
import getTweakwiseFacetFiltersFromUrlFacetParam from '../lib/getTweakwiseFacetFiltersFromUrlFacetParam'
import { removeTweakwiseFiltersFromUrl } from '../lib/updateUrlWithTweakwiseFacetFilter'
import { LandingPageUrlFragment } from '../lib/useLandingPageUrls.generated'
import theme from '../theme'
import ButtonPrimary from './ButtonPrimary'
import CategoryFiltersForm from './CategoryFiltersForm'
import Modal, {
  Content as ModalContent,
  TitleWrapper as ModalTitleWrapper,
} from './Modal'

const StyledModal = styled(Modal)`
  ${ModalTitleWrapper} {
    padding: ${theme.spacing.lg};

    @media ${minWidth('lg')} {
      padding: ${theme.spacing['4xl']} 0 ${theme.spacing.xl}
        ${theme.spacing['4xl']};
    }
  }

  ${ModalContent} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${theme.spacing.xs} 0 0;
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  text-decoration: underline;
  padding: 0 ${theme.spacing.lg};
`

const StyledCategoryFiltersForm = styled(CategoryFiltersForm)`
  padding: 0 ${theme.spacing.lg} ${theme.spacing.lg};
`

interface Props {
  show: boolean
  close: () => void
  facets: TweakwiseNavigationFacet[]
  landingPageUrls: LandingPageUrlFragment[]
  resultCount: number
  router: NextRouter
  filterExplanations?: CraftFilterExplanationListsFragment
  setIsFacetMenuActive: (isFacetMenuActive: boolean) => void
}

const CategoryFiltersFormModal = ({
  show,
  close,
  facets,
  landingPageUrls,
  resultCount,
  filterExplanations,
  router,
  setIsFacetMenuActive,
}: Props) => {
  const facetParam = router.query.facets as string

  const activeTweakwiseFacetFilters = facetParam
    ? getTweakwiseFacetFiltersFromUrlFacetParam(facetParam)
    : []

  const activeFiltersFromLandingPageUrls = landingPageUrls.filter((urlObject) =>
    activeTweakwiseFacetFilters.some(
      (facetFilter) =>
        facetFilter.key === urlObject.key &&
        facetFilter.values.includes(urlObject.value),
    ),
  )

  const preventShallowRoute =
    activeFiltersFromLandingPageUrls.some(
      (urlObject) => urlObject.landingPageUrl !== urlObject.currentLandingPage,
    ) || activeFiltersFromLandingPageUrls.length === 0

  return (
    <StyledModal
      title={t({
        message: 'Filter by',
      })}
      show={show}
      close={close}
      preventCloseOnRouteChange
      bottom={
        <ButtonPrimary
          analyticsContext="category.filters.form.modal"
          analyticsName="show.results"
          colorType="neutral"
          onClick={preventShallowRoute ? router.reload : close}
        >
          <Plural
            value={resultCount}
            one="show # product"
            other="show # products"
          />
        </ButtonPrimary>
      }
    >
      <div>
        <StyledButtonUnstyled
          analyticsContext="category.active.filters"
          analyticsName="remove.all.filters"
          onPress={() => removeTweakwiseFiltersFromUrl(router)}
        >
          <Trans>Remove filters</Trans>
        </StyledButtonUnstyled>

        <StyledCategoryFiltersForm
          facets={facets}
          landingPageUrls={landingPageUrls}
          hasShallowRouteUntilSubmit
          filterExplanations={filterExplanations}
          setIsFacetMenuActive={setIsFacetMenuActive}
        />
      </div>
    </StyledModal>
  )
}

export default CategoryFiltersFormModal
