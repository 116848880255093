import {
  FacetAttributeFragment,
  FacetSettingsFragment,
} from '@emico-hooks/tweakwise-navigation'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form'

import { hashCode } from '@emico/utils'

import { TweakwiseNavigationSelectedAttribute } from '../lib/customTypes'
import { LandingPageUrlFragment } from '../lib/useLandingPageUrls.generated'
import theme from '../theme'
import CheckboxCustom from './CheckboxCustom'

const StyledCheckboxCustom = styled(CheckboxCustom)`
  margin-bottom: ${theme.spacing.sm};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledCheckboxCustomColorSwatch = styled(CheckboxCustom, {
  shouldForwardProp: (prop) =>
    !['isSelectedControlled'].includes(prop.toString()),
})<{ isSelectedControlled?: boolean }>`
  display: inline-grid;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: ${theme.spacing.xs};
  border: ${theme.borders.default};
  border-color: ${({ isSelectedControlled }) =>
    isSelectedControlled ? theme.colors.borderDark : theme.colors.border};
`

const Color = styled('span', {
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop.toString()),
})<{ backgroundColor?: string }>`
  width: 22px;
  height: 22px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: 0;
`

interface ColorSwatchCheckboxProps<T extends FieldValues> {
  register: UseFormRegister<T>
  isSelected: boolean
  facetSettings: FacetSettingsFragment
  attribute: FacetAttributeFragment
  landingPageUrlForAttribute: LandingPageUrlFragment | undefined
  handleStateChange?(isSelected: boolean): void
  onChange: (facet: TweakwiseNavigationSelectedAttribute) => void
}

const ColorSwatchCheckbox = <T extends FieldValues>({
  isSelected,
  register,
  facetSettings,
  attribute,
  landingPageUrlForAttribute,
  handleStateChange,
  onChange,
}: ColorSwatchCheckboxProps<T>) =>
  attribute.colorSwatch ? (
    <StyledCheckboxCustomColorSwatch
      value={attribute.title}
      isSelectedControlled={isSelected}
      {...register(attribute.title as Path<T>)}
      showButton={false}
      hasCustomSelectState={false}
      handleStateChange={handleStateChange}
      onChange={() =>
        onChange({
          facetSettings: facetSettings,
          attribute,
          landingPageUrlForAttribute,
        })
      }
    >
      <Color backgroundColor={attribute.colorSwatch?.value}>
        {attribute.title}
      </Color>
    </StyledCheckboxCustomColorSwatch>
  ) : null

interface Props<T extends FieldValues> {
  register: UseFormRegister<T>
  facetSettings: FacetSettingsFragment
  attribute: FacetAttributeFragment
  landingPageUrls: LandingPageUrlFragment[]
  onChange: (facet: TweakwiseNavigationSelectedAttribute) => void
}

const CategoryFilterAttributes = <T extends FieldValues>({
  register,
  facetSettings,
  attribute,
  landingPageUrls,
  onChange,
}: Props<T>) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)

  const handleStateChange = (isSelected: boolean) => setIsSelected(isSelected)

  useEffect(() => {
    setIsSelected(attribute.isSelected)
  }, [attribute.isSelected])

  const landingPageUrlForAttribute = landingPageUrls.find(
    (urlObject) =>
      urlObject.key === facetSettings.attributeName &&
      urlObject.value === attribute.title,
  )

  switch (facetSettings.attributeName) {
    case 'color':
    case 'ae-color':
      return (
        <ColorSwatchCheckbox
          key={attribute.title}
          register={register}
          isSelected={isSelected}
          facetSettings={facetSettings}
          attribute={attribute}
          landingPageUrlForAttribute={landingPageUrlForAttribute}
          handleStateChange={handleStateChange}
          onChange={onChange}
        />
      )
    default:
      return (
        <StyledCheckboxCustom
          key={attribute.title}
          value={attribute.title}
          isSelectedControlled={isSelected}
          {...register(
            String(
              hashCode(
                `${facetSettings.title}-${encodeURIComponent(attribute.title)}`,
              ),
            ) as Path<T>,
          )}
          handleStateChange={handleStateChange}
          onChange={() => {
            onChange({
              facetSettings,
              attribute,
              landingPageUrlForAttribute,
            })
          }}
        >
          {attribute.title}
        </StyledCheckboxCustom>
      )
  }
}

export default CategoryFilterAttributes
