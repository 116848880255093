import { TweakwiseNavigationFacetFilter } from '@emico/graphql-schema-types/src'

import joinTweakwiseFacetValues from './joinTweakwiseFacetValues'

/**
 * Function to convert array of TweakwiseNavigationFacetFilter objects into a URL facet param
 *
 * Example input:
 * ```
 * [
 *  {
 *    "key": "color",
 *    "values": [
 *      "Gray"
 *    ]
 *  },
 *  {
 *    "key": "trampoline_shape",
 *    "values": [
 *      "Round"
 *    ]
 *  }
 * ]
 * ```
 *
 * Example result: `trampoline_shape%3ARound%2Fcolor%3AGray`
 *
 * @param tweakwiseNavigationFacetFilters Array of TweakwiseNavigationFacetFilter objects that should be converted into URL facet param
 * @returns string compatible for URL facet param
 */

export default function getUrlFacetParamFromTweakwiseFacetFilters(
  tweakwiseNavigationFacetFilters: TweakwiseNavigationFacetFilter[],
) {
  const urlParts = tweakwiseNavigationFacetFilters.map(
    (filter) => `${filter.key}:${joinTweakwiseFacetValues(filter.values)}`,
  )

  const urlParam =
    urlParts.length !== 0 ? encodeURIComponent(urlParts.join('/')) : undefined

  return urlParam
}
