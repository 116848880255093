import { ComponentSlider } from '@emico-react/component-slider'
import styled from '@emotion/styled'
import React from 'react'

import { maxWidth, minWidth } from '@emico/styles'

import { CraftQuickFiltersFragment } from '../lib/craftFragments.generated'
import {
  IconWrapper,
  PrevButton,
  StyledNextButton,
} from '../overrides/ComponentSlider'
import theme from '../theme'
import CraftImage from './CraftImage'
import CraftLink from './CraftLink'

const StyledComponentSliderMobile = styled(ComponentSlider)`
  @media ${minWidth('md')} {
    display: none;
  }
`

const StyledComponentSliderTablet = styled(ComponentSlider)`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const StyledComponentSliderDesktop = styled(ComponentSlider)`
  ${PrevButton}, ${StyledNextButton} {
    height: 100%;
    top: 100%;
    width: 100px;
    display: flex;
    align-items: center;
  }

  ${IconWrapper} {
    box-shadow: 0 0 6px 1px ${theme.colors.grayMiddle};
  }

  ${PrevButton} {
    left: 0;
    right: auto;
    justify-content: flex-end;
  }

  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledCraftLink = styled(CraftLink)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing.sm};
  background-color: ${theme.colors.backgroundLight};
  color: ${theme.colors.text};
  text-decoration: none;
  height: 100%;

  &:hover {
    color: ${theme.colors.text};
    text-decoration: none;
  }

  @media ${minWidth('lg')} {
    padding: ${theme.spacing.lg} ${theme.spacing.sm};
    ${theme.animation.zoomNestedImage(
      1.1,
      theme.transition.durations.extraSlow,
    )};
  }
`

const Figure = styled.figure`
  margin-bottom: ${theme.spacing.sm};
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
  object-fit: contain;
`

const FilterTextWrapper = styled.div`
  display: grid;
  text-align: center;
  flex-grow: 1;
`

const Name = styled.span`
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.sm};
  }
`

const Subtitle = styled.span`
  font-size: ${theme.fontSizes.xs};

  @media ${minWidth('md')} {
    font-size: ${theme.fontSizes.sm};
  }=
`

interface FilterProps {
  filter: CraftQuickFiltersFragment
  hasSmallImage?: boolean
}

const Filter = ({ filter, hasSmallImage }: FilterProps) => {
  const image = filter.image?.[0]

  return filter.filterLink?.url ? (
    <StyledCraftLink craftLink={filter.filterLink}>
      <Figure>
        <StyledCraftImage
          image={image}
          width={300}
          height={hasSmallImage ? 60 : 120}
        />
      </Figure>

      <FilterTextWrapper>
        <Name>{filter.quickFilterName}</Name>

        {filter.subtitle && <Subtitle>{filter.subtitle}</Subtitle>}
      </FilterTextWrapper>
    </StyledCraftLink>
  ) : null
}

interface Props {
  filters: CraftQuickFiltersFragment[]
}

const QuickFilters = ({ filters, ...other }: Props) => {
  const slideCount = filters.length
  const slidesToShowMobile = 3.5
  const slidesToShowTablet = 4.5
  const slidesToShowDesktop = 6.5

  return (
    <>
      <StyledComponentSliderMobile
        snapAlign="start"
        slideGap={2}
        slidesToShow={slidesToShowMobile}
        {...other}
      >
        {filters.map((filter, index) => (
          <Filter
            key={`filter.quickFilterName-${index}`}
            filter={filter}
            hasSmallImage
          />
        ))}
      </StyledComponentSliderMobile>

      <StyledComponentSliderTablet
        snapAlign="start"
        slideGap={3}
        slidesToShow={slidesToShowTablet}
        {...other}
      >
        {filters.map((filter, index) => (
          <Filter key={`filter.quickFilterName-${index}`} filter={filter} />
        ))}
      </StyledComponentSliderTablet>

      <StyledComponentSliderDesktop
        snapAlign="start"
        slideGap={3}
        slidesToShow={slidesToShowDesktop}
        showArrows={slideCount > slidesToShowDesktop}
        {...other}
      >
        {filters.map((filter, index) => (
          <Filter key={`filter.quickFilterName-${index}`} filter={filter} />
        ))}
      </StyledComponentSliderDesktop>
    </>
  )
}

export default QuickFilters
