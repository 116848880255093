import { ButtonUnstyled } from '@emico-react/buttons'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import {
  Transition,
  TransitionGroup,
  TransitionStatus,
} from 'react-transition-group'
import { ENTERING, EXITED, EXITING } from 'react-transition-group/Transition'

import CrossIcon from '../icons/CrossIcon'
import {
  TweakwiseNavigationFacet,
  TweakwiseNavigationSelectedAttribute,
} from '../lib/customTypes'
import { opacityStates } from '../lib/transitionStates'
import updateUrlWithTweakwiseFacetFilter, {
  removeTweakwiseFiltersFromUrl,
} from '../lib/updateUrlWithTweakwiseFacetFilter'
import { LandingPageUrlFragment } from '../lib/useLandingPageUrls.generated'
import theme from '../theme'

const ActiveFiltersWrapper = styled('div', {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ state }) => opacityStates[state]};
  margin-bottom: ${theme.spacing.xs};
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.span`
  margin-right: ${theme.spacing.sm};
  flex-shrink: 0;
`

const StyledTransitionGroup = styled(TransitionGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.sm};
`

const ActiveFilterButton = styled(ButtonUnstyled, {
  shouldForwardProp: (prop) =>
    !['state', 'shouldTransition'].includes(prop.toString()),
})<{ state: TransitionStatus; shouldTransition: boolean }>`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.sm};
  margin-right: ${theme.spacing.xs};
  font-size: ${theme.fontSizes.sm};
  background-color: ${theme.colors.backgroundLight};

  transition-property: ${({ shouldTransition }) =>
    shouldTransition ? theme.transition.properties.common : 'none'};
  transition-duration: ${theme.transition.durations.normal};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  opacity: ${({ state }) =>
    [ENTERING, EXITED, EXITING].includes(state) ? 0 : 1};
`

const RemoveFiltersButton = styled(ButtonUnstyled)`
  font-size: ${theme.fontSizes.sm};
  color: ${theme.colors.grayBrown};
`

const StyledCrossIcon = styled(CrossIcon)`
  margin-left: ${theme.spacing.md};
`

interface Props {
  facets: TweakwiseNavigationFacet[]
  isLoadingFacets: boolean
  landingPageUrls: LandingPageUrlFragment[]
  isLoadingLandingPageUrls: boolean
  setIsFacetMenuActive: (isFacetMenuActive: boolean) => void
}

const CategoryActiveFilters = ({
  facets,
  isLoadingFacets,
  landingPageUrls,
  isLoadingLandingPageUrls,
  setIsFacetMenuActive,
  ...other
}: Props) => {
  const router = useRouter()
  const nodeRef = useRef(null)

  const activeFacetFilters = facets
    ?.flatMap((facet) => {
      const activeFacetFilters = facet.attributes.map((attribute) => {
        const landingPageUrlForAttribute = landingPageUrls.find(
          (urlObject) =>
            urlObject.key === facet.facetSettings.attributeName &&
            urlObject.value === attribute.title,
        )

        return attribute.isSelected
          ? {
              facetSettings: facet.facetSettings,
              attribute,
              landingPageUrlForAttribute,
            }
          : null
      })

      return activeFacetFilters
    })
    .filter(stripMaybes)

  const [activeFacetFiltersState, setActiveFacetFiltersState] =
    useState<TweakwiseNavigationSelectedAttribute[]>(activeFacetFilters)
  const activeFacetFiltersStateCount = activeFacetFiltersState.length
  const hasActiveFilters = activeFacetFiltersStateCount > 0

  const handleRemoveSelectedFilter = (
    facetFilter: TweakwiseNavigationSelectedAttribute,
  ) => {
    setIsFacetMenuActive(true)
    updateUrlWithTweakwiseFacetFilter(router, facetFilter)
  }

  useEffect(() => {
    if (
      !isLoadingFacets &&
      !isLoadingLandingPageUrls &&
      JSON.stringify(activeFacetFilters) !==
        JSON.stringify(activeFacetFiltersState)
    ) {
      setActiveFacetFiltersState(activeFacetFilters)
    }
  }, [
    activeFacetFilters,
    activeFacetFiltersState,
    isLoadingFacets,
    isLoadingLandingPageUrls,
  ])

  return (
    <Transition nodeRef={nodeRef} in={hasActiveFilters} timeout={300}>
      {(state: TransitionStatus) => (
        <ActiveFiltersWrapper ref={nodeRef} state={state} {...other}>
          <Row>
            <Text>
              <Trans>Chosen filters:</Trans>
            </Text>

            <StyledTransitionGroup>
              {activeFacetFiltersState.map((facetFilter) => (
                <Transition
                  key={`${facetFilter.attribute.title}_${facetFilter.facetSettings.attributeName}`}
                  nodeRef={nodeRef}
                  timeout={300}
                >
                  {(state: TransitionStatus) => (
                    <ActiveFilterButton
                      ref={nodeRef}
                      state={state}
                      shouldTransition={
                        activeFacetFiltersStateCount > 1 ||
                        JSON.stringify(activeFacetFilters) ===
                          JSON.stringify(activeFacetFiltersState)
                      }
                      analyticsContext="category.active.filters"
                      analyticsName={facetFilter.attribute.title}
                      onPress={() => handleRemoveSelectedFilter(facetFilter)}
                    >
                      {facetFilter.facetSettings.title}:{' '}
                      {facetFilter.attribute.title}
                      <StyledCrossIcon />
                    </ActiveFilterButton>
                  )}
                </Transition>
              ))}
            </StyledTransitionGroup>
          </Row>

          <RemoveFiltersButton
            analyticsContext="category.active.filters"
            analyticsName="remove.all.filters"
            onPress={() => removeTweakwiseFiltersFromUrl(router)}
          >
            <Trans>Remove all filters</Trans>
          </RemoveFiltersButton>
        </ActiveFiltersWrapper>
      )}
    </Transition>
  )
}

export default CategoryActiveFilters
