const facetPrefix = '&tn_fk_'
// const facetPrefix = '&tk_fn_' // temp change due to backend typo

/**
 * Function to convert Tweakwise facet url into a TweakwiseNavigationFacetFilter object with facet key and values
 *
 * Example input: `tn_cid=100093&tn_fk_color=Gray&tn_fk_trampoline_shape=Round`
 *
 * Example result:
 * ```
 * [
 *  {
 *    "key": "color",
 *    "values": [
 *      "Gray"
 *    ]
 *  },
 *  {
 *    "key": "trampoline_shape",
 *    "values": [
 *      "Round"
 *    ]
 *  }
 * ]
 * ```
 *
 * @param url Tweakwise facet URL that should be converted into TweakwiseNavigationFacetFilter
 * @returns TweakwiseNavigationFacetFilter object with key and values
 */

export default function getFacetFiltersFromTweakwiseUrl(url: string) {
  // Split Tweakwise URL on '&tn_fk' string which represents a facet
  const attributeTweakwiseUrlParts = url?.split(/(?=&tn_fk_)/)
  // const attributeTweakwiseUrlParts = url?.split(/(?=&tk_fn_)/) // temp due to backend typo

  // Array of Tweakwise URL-based facets with key and values
  const attributeFacets =
    (attributeTweakwiseUrlParts
      ?.map(
        (part) => part.includes(facetPrefix) && part.replace(facetPrefix, ''),
      )
      .filter(Boolean) as string[]) ?? []

  const tweakwiseNavigationFacetFilters = attributeFacets.map(
    (attributeFacet) => {
      const [key, valuesString] = attributeFacet.split('=')

      const values = decodeURIComponent(valuesString).split('|')

      return {
        key,
        values,
      }
    },
  )

  return tweakwiseNavigationFacetFilters
}
