/**
 * Function to join Tweakwise facet filter values
 *
 * @param facetFilter Tweakwise facet filter the values should be joined for
 * @returns string with joined facet filter values
 */

export default function joinTweakwiseFacetValues(facetFilterValues: string[]) {
  return facetFilterValues?.map((value) => encodeURIComponent(value)).join('#')
}
