import {
  useQuery,
  gql,
  TypedDocumentNode,
  QueryHookOptions,
} from '@apollo/client'
import { getCacheableContext } from '@emico-utils/graphql-data-utils'

import { TweakwiseNavigationFacetFilter } from './customTypes'
import {
  LandingPageUrlsQuery,
  LandingPageUrlsQueryVariables,
  LandingPageUrlFragment,
} from './useLandingPageUrls.generated'

const landingPageUrlFragment = gql`
  fragment LandingPageUrlFragment on LandingPageUrl {
    hashKey
    key
    defaultFallbackPage
    currentLandingPage
    landingPageUrl
    url
    value
  }
` as TypedDocumentNode<LandingPageUrlFragment, never>

export const landingPageUrlsQuery = gql`
  query landingPageUrls(
    $uid: ID!
    $tweakwiseItemNumber: [Int]!
    $facets: [FacetInput]!
    $selectedFacets: [FacetInput]
    $currentUri: String
  ) {
    landingPageUrls(
      uid: $uid
      tweakwise_item_number: $tweakwiseItemNumber
      facets: $facets
      selected_facets: $selectedFacets
      current_uri: $currentUri
    ) {
      ...LandingPageUrlFragment
    }
  }

  ${landingPageUrlFragment}
` as TypedDocumentNode<LandingPageUrlsQuery, LandingPageUrlsQueryVariables>

interface landingPageUrlsVariables {
  categoryUid: string
  /**
   * One or more Tweakwise item number(s) to query.
   */
  tweakwiseItemNumbers: number[]
  /**
   * Available Tweakwise Navigation facets
   */
  facets: TweakwiseNavigationFacetFilter[]
  /**
   * Selected Tweakwise facets
   */
  selectedFacets?: TweakwiseNavigationFacetFilter[]
  /**
   * Current URI
   */
  currentUri?: string
}

/**
 * Query to get landing page urls for Tweakwise filter attributes
 *
 * @param variables object to filter the results
 * @returns array of objects to access landing page urls for Tweakwise filter attributes
 *
 * Project usage example:
 *
 * ```
 * const Component = () => {
 *  const variables = getLandingPageUrlsVariables(
 *    categoryUid,
 *    searchParam ? rootCategoryTweakwiseItemNumber : tweakwiseItemNumber,
 *    facets,
 *    facetParam,
 *    router.asPath,
 *  )
 *
 *  const { data: landingPageUrlsData } = useLandingPageUrls(tweakwiseLandingPageVariables)
 *
 *  [...]
 * }
 * ```
 */

export function useLandingPageUrls(
  variables: landingPageUrlsVariables,
  options: Omit<
    QueryHookOptions<LandingPageUrlsQuery, LandingPageUrlsQueryVariables>,
    'variables'
  > = {},
) {
  const { data, error, ...others } = useQuery(landingPageUrlsQuery, {
    ...options,
    variables: {
      uid: variables.categoryUid,
      tweakwiseItemNumber: variables.tweakwiseItemNumbers,
      facets: variables.facets,
      selectedFacets: variables.selectedFacets ?? null,
      currentUri: variables.currentUri ?? null,
    },
    context: getCacheableContext(),
  })

  return {
    ...others,
    error,
    data: data?.landingPageUrls,
  }
}
