import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import getPaginationUrl from '../lib/getPaginationUrl'
import updateUrlWithPageKey from '../lib/updateUrlWithPageKey'
import Pagination from './Pagination'
import PaginationButton from './PaginationButton'

interface Props {
  currentPageIndex: number
  pageCount: number
}

const CategoryPagination = ({
  currentPageIndex,
  pageCount,
  ...other
}: Props) => {
  const router = useRouter()

  const handlePaginationClick = (pageKey: number) => {
    updateUrlWithPageKey(router, pageKey)
  }

  const prevUrl = getPaginationUrl(router, currentPageIndex - 1)
  const nextUrl = getPaginationUrl(router, currentPageIndex + 1)

  return (
    <>
      <Head>
        {currentPageIndex > 1 && <link rel="prev" href={prevUrl} />}
        {currentPageIndex !== pageCount && <link rel="next" href={nextUrl} />}
      </Head>

      <Pagination
        current={currentPageIndex}
        total={pageCount}
        renderElement={({ current, title, isDivider, key }) => (
          <PaginationButton
            key={key}
            analyticsContext="category.pagination"
            analyticsName={`to.${current}`}
            onPress={() => handlePaginationClick(current)}
            isDivider={isDivider}
            isActive={current === currentPageIndex}
          >
            {title}
          </PaginationButton>
        )}
        {...other}
      />
    </>
  )
}

export default CategoryPagination
