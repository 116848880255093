import getTweakwiseFacetFiltersFromUrlFacetParam from './getTweakwiseFacetFiltersFromUrlFacetParam'
import getTweakwisePageSize from './getTweakwisePageSize'

/**
 * Function to get Tweakwise navigation variables to pass as query params
 *
 * @param tweakwiseItemNumber Category's Tweakwise item number that should be used for query's tweakwiseItemNumbers
 * @param pageParam Next route's page param
 * @param facetParam Next route's facet param
 * @param sortParam Next route's sort param
 * @param initialLandingPageFacetParam Initial landing page facet param
 * @returns object with Tweakwise Navigation variables
 */

export default function getTweakwiseNavigationVariables(
  tweakwiseItemNumber: string,
  pageParam: string,
  facetParam: string,
  sortParam: string,
  initialLandingPageFacetParam?: string,
) {
  const tweakwiseFacetFilters = facetParam
    ? getTweakwiseFacetFiltersFromUrlFacetParam(facetParam)
    : initialLandingPageFacetParam
    ? getTweakwiseFacetFiltersFromUrlFacetParam(initialLandingPageFacetParam)
    : []

  return {
    tweakwiseItemNumbers: [tweakwiseItemNumber ?? ''],
    page: pageParam ? Number(pageParam) : 1,
    pageSize: getTweakwisePageSize(),
    facets: tweakwiseFacetFilters,
    sortKey: sortParam ? decodeURIComponent(sortParam) : undefined,
  }
}
