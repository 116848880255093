/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { TweakwiseNavigationPropertiesSortField } from '@emico/graphql-schema-types'
import { maxWidth } from '@emico/styles'

import updateUrlWithTweakwiseSortKey from '../lib/updateUrlWithTweakwiseSortKey'
import theme from '../theme'
import Select from './Select'

const Form = styled.form`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Label = styled.label`
  margin: 0;
  margin-right: ${theme.spacing.md};
  flex-shrink: 0;
`

const StyledSelect = styled(Select as any)`
  flex-grow: 1;

  @media ${maxWidth('md')} {
    font-size: ${theme.fontSizes.sm};

    select {
      font-weight: ${theme.fontWeights.bold};
      text-transform: uppercase;
    }
  }
` as typeof Select

interface FormValues {
  sortKey: string
}

interface Props {
  sortOptions: TweakwiseNavigationPropertiesSortField[]
  showLabel?: boolean
}

const CategorySortForm = ({
  sortOptions,
  showLabel = true,
  ...other
}: Props) => {
  const router = useRouter()
  const sortParam = router.query.sort as string
  const { register, control, handleSubmit, watch, reset, formState } =
    useForm<FormValues>()

  const sortKeyValue = watch('sortKey')
  const activeSortParam = sortParam ? decodeURIComponent(sortParam) : undefined
  const defaultSortOption = activeSortParam ?? sortOptions[0].title

  const onSubmit = useCallback(
    (values: FormValues) => {
      updateUrlWithTweakwiseSortKey(router, values.sortKey)
    },
    [router],
  )

  // Reset form with defaultValues including defaultSortOption, because defaultSortOption is not available on initial render
  useEffect(() => {
    if (defaultSortOption) {
      reset({
        sortKey: defaultSortOption,
      })
    }
  }, [reset, defaultSortOption])

  // Submit form on sortKeyValue change
  useEffect(() => {
    if (sortKeyValue && formState.isDirty && sortKeyValue !== activeSortParam) {
      onSubmit({ sortKey: sortKeyValue })
    }
  }, [formState.isDirty, onSubmit, activeSortParam, sortKeyValue])

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...other}>
      {showLabel && (
        <Label htmlFor="sortKey">
          <Trans>Sort by:</Trans>
        </Label>
      )}

      <StyledSelect
        control={control}
        defaultValue={defaultSortOption}
        {...register('sortKey')}
      >
        <>
          {sortOptions.map((option) => (
            <option key={option.title} value={option.title}>
              {option.displayTitle}
            </option>
          ))}
        </>
      </StyledSelect>
    </Form>
  )
}

export default CategorySortForm
