import { NextRouter } from 'next/router'

import { TweakwiseNavigationSelectedAttribute } from './customTypes'
import getFacetFiltersFromTweakwiseUrl from './getFacetFiltersFromTweakwiseUrl'
import getUrlFacetParamFromTweakwiseFacetFilters from './getUrlFacetParamFromTweakwiseFacetFilters'

/**
 * Function to update URL with Tweakwise facet data
 *
 * @param router from next/route useRouter()
 * @param facetFilter Tweakwise facet filter the URL should be updated with
 */

export default function updateUrlWithTweakwiseFacetFilter(
  router: NextRouter,
  facetFilter: TweakwiseNavigationSelectedAttribute,
  hasShallowRouteUntilSubmit: boolean = false,
) {
  if (
    !facetFilter.attribute ||
    !facetFilter.facetSettings ||
    !facetFilter.landingPageUrlForAttribute
  ) {
    return
  }

  const attributeTweakwiseUrl = facetFilter.landingPageUrlForAttribute?.url

  const landingPageUrl = facetFilter.landingPageUrlForAttribute?.landingPageUrl
  const currentLandingPageUrl =
    facetFilter.landingPageUrlForAttribute.currentLandingPage

  const defaultFallbackPage =
    facetFilter.landingPageUrlForAttribute?.defaultFallbackPage

  const tweakwiseNavigationFacetFilters = attributeTweakwiseUrl
    ? getFacetFiltersFromTweakwiseUrl(attributeTweakwiseUrl)
    : []

  const urlFacetParam = getUrlFacetParamFromTweakwiseFacetFilters(
    tweakwiseNavigationFacetFilters,
  )

  const hasSearchQuery = Boolean(router.query.query)
  const hasLandingPageUrl = Boolean(landingPageUrl)
  const includeRouteSlugs = !landingPageUrl && !defaultFallbackPage
  const isShallowRoute =
    (!hasLandingPageUrl && !defaultFallbackPage) ||
    landingPageUrl === currentLandingPageUrl ||
    hasShallowRouteUntilSubmit

  if (router.query.page) {
    router.query.page = []
  }

  router.query.facets = urlFacetParam ?? []
  const { slug, ...routerQuery } = router.query

  router.push(
    {
      pathname: hasSearchQuery
        ? 'search'
        : landingPageUrl ?? defaultFallbackPage ?? router.pathname,

      query: includeRouteSlugs
        ? {
            ...router.query,
          }
        : {
            ...routerQuery,
          },
    },
    undefined,
    {
      shallow: isShallowRoute,
    },
  )
}

export function removeTweakwiseFiltersFromUrl(router: NextRouter) {
  const isShallowRoute = router.query.slug
    ? router.query.slug.length <= 1
    : false

  router.query.facets = []
  router.query.slug = router.query.slug?.slice(0, 1) ?? []

  router.push(
    {
      pathname: router.pathname,
      query: {
        ...router.query,
      },
    },
    undefined,
    {
      shallow: isShallowRoute,
    },
  )
}
